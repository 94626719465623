import React from 'react'
import { Box } from '@mui/material'

const PersonalData = () => {
  return (
    <Box height={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      Personal Data
    </Box>
  )
}

export default PersonalData
